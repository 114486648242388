import { hasNames } from "../utils";
import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";
import { useRef } from "react";

import ContactUsFooter from "../components/contact-us-footer";
import FiftyFifty from "../components/fifty-fifty";
import Frame from "../components/frame";
import { HeadMeta } from "../components/head-meta";
import InteractiveScroll from "../components/interactive-scroll";
import InternalHero from "../components/internal-hero";
import TriplePoints from "../components/triple-points";

import * as com from "../styles/common.module.scss";
import * as grd from "../styles/grid.module.scss";
import * as typ from "../styles/typography.module.scss";
import * as contactFooter from "../styles/contact-us-footer.module.scss";

import EssentialIcon from "../images/essential.icon.svg";
import MarketIcon from "../images/market.icon.svg";
import AspirationalIcon from "../images/aspirational.icon.svg";

export const Head = () => {
	return <HeadMeta title="Approach" />;
};

const OurApproachPage = () => {
	const heroRef = useRef(null);
	const bespokeRef = useRef(null);
	const longtermRef = useRef(null);
	const foundationRef = useRef(null);
	const historyRef = useRef(null);
	const contactRef = useRef(null);

	const scrollLocations = [
		{ label: "Approach", ref: heroRef },
		{ label: "Bespoke", ref: bespokeRef },
		{ label: "Long Term", ref: longtermRef },
		{ label: "Foundation", ref: foundationRef },
		{ label: "Our History", ref: historyRef },
		{ label: "Contact", ref: contactRef },
	];

	return (
		<Frame>
			<InteractiveScroll locations={scrollLocations} />

			<InternalHero
				ref={heroRef}
				page={"approachHero"}
				eyebrow={"Our Approach"}
				backdrop={
					<StaticImage
						src='../images/heros/ccm-hero-approach.jpg'
						alt="background image"
						aria-hidden="true"
						placeholder="blurred"
						layout="fullWidth"
						loading="eager"
						quality={85}
					/>
				}>
				<h1>Growing your impact</h1>
				<p>
				As outsourced chief investment officers, we build and deliver investment solutions tailored to the unique needs of your organization. Our objective-based portfolios are constructed to meet your essential obligations while aspiring to increase your long-term giving power.
				</p>
			</InternalHero>

			{/* Triple Points Block */}
			<div className={com.sectionSpacer} ref={bespokeRef}>
					<div className={hasNames(grd.container, com.monobrow)}>Essential, Market, Aspirational</div>
					<h2 className={hasNames(grd.container, com.centerlineHeader)}>
					We take a three-tiered approach to helping you manage the growth of assets that benefit communities and causes. 
					</h2>
				</div>
				<TriplePoints
					points={[
						{
							image: <EssentialIcon />,
							copy: (
								<p><strong>Essential:</strong> Fulfill your annual giving obligation while ensuring you cover your operating costs. </p>
							),
						},
						{
							image: <MarketIcon />,
							copy: <p><strong>Market:</strong> Keep up with inflation so that you can preserve your real value.</p>,
						},
						{
							image: <AspirationalIcon />,
							copy: <p><strong>Aspirational:</strong> Reach beyond achieving your essential and market objectives and grow your impact over time.</p>,
						},
					]}
				/>

			<FiftyFifty reverseMobile={true} useRef={bespokeRef}>
				<StaticImage
					src="../images/ccm-bespoke.jpg"
					alt="First Security Building"
					aspectRatio={1 / 1}
					layout="constrained"
					width={640}
					height={640}
					className={com.imageClippedLeft}
					quality={85}
				/>
				<div className="extra-gap">
					<div className={typ.eyebrow}>
					Bespoke Solutions
					</div>
					<h2 className={com.browedHeader}>
					Customizing your Portfolio
					</h2>
					<div className={typ.bodyLargeLight}>
					We provide customized solutions specific to foundations and endowments. Our experienced and knowledgeable team has years of experience crafting portfolios that address your unique and complex needs, including risk, and after fee/after tax returns.
					</div>
				</div>
			</FiftyFifty>

			<FiftyFifty reverseMobile={false} useRef={longtermRef}>
			<div className="extra-gap">
					<div className={typ.eyebrow}>
					Long-term approach
					</div>
					<h2 className={com.browedHeader}>
					Crafting your Legacy
					</h2>
					<div className={typ.bodyLargeLight}>
					We work with you to determine your long-term objectives, then collaborate to build a portfolio that aligns targeted net returns with
					desired goals and appropriate risk.
					</div>
				</div>
				<StaticImage
					src="../images/ccm-long-term.jpg"
					alt="First Security Building"
					aspectRatio={1 / 1}
					layout="constrained"
					width={640}
					height={640}
					className={com.imageClipped}
					quality={85}
				/>
			</FiftyFifty>

			<FiftyFifty reverseMobile={true} useRef={foundationRef}>
				<StaticImage
					src="../images/ccm-bottom-up.jpg"
					alt="First Security Building"
					aspectRatio={1 / 1}
					layout="constrained"
					width={640}
					height={640}
					className={com.imageClippedLeft}
					quality={85}
				/>
				<div className="extra-gap">
					<div className={typ.eyebrow}>
					Bottom-up portfolio construction
					</div>
					<h2 className={com.browedHeader}>
					Building your Foundation
					</h2>
					<div className={typ.bodyLargeLight}>
					Rather than the typical top-down approach to investing, we draw upon the institutional experience and insight of our team to recommend
					investment opportunities that may yield greater long-term returns.
					</div>
				</div>
			</FiftyFifty>

			<ContactUsFooter
				ref={contactRef}
				backdrop={
					<StaticImage
						className={contactFooter.image}
						src={`../images/ccm-footer-1.png`}
						alt=""
						aria-hidden="true"
						layout="fullWidth"
						quality={85}
					/>
				}/>
		</Frame>
	);
};

export default OurApproachPage;
