// extracted by mini-css-extract-plugin
export var approachHero = "internal-hero-module--approachHero--652f5";
export var backdrop = "internal-hero-module--backdrop--f05eb";
export var body = "internal-hero-module--body--95d64";
export var bodyLarge = "internal-hero-module--bodyLarge--6b78b";
export var bodyLargeLight = "internal-hero-module--bodyLargeLight--1c6a4";
export var bodySmall = "internal-hero-module--bodySmall--687cc";
export var bold = "internal-hero-module--bold--e67d5";
export var brow = "internal-hero-module--brow--4aa56";
export var childWrap = "internal-hero-module--childWrap--96692";
export var date = "internal-hero-module--date--3d97c";
export var eyebrow = "internal-hero-module--eyebrow--e30ff";
export var filter = "internal-hero-module--filter--87ab4";
export var homepageHeadline = "internal-hero-module--homepageHeadline--d76cb";
export var internalHeadline = "internal-hero-module--internalHeadline--bec42";
export var internalHero = "internal-hero-module--internalHero--993b1";
export var italic = "internal-hero-module--italic--414bd";
export var link = "internal-hero-module--link--b08ab";
export var linkSmall = "internal-hero-module--linkSmall--e37de";
export var overlay = "internal-hero-module--overlay--71f9f";
export var quote = "internal-hero-module--quote--937bc";
export var teamHero = "internal-hero-module--teamHero--20bc3";